(function () {
	'use strict';

	angular
		.module('app')
		.factory('salesforceConfig', ['$sce', 'utilities', salesforceConfig]);

	function salesforceConfig($sce, utilities) {
		return {
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			allowTextFieldMap: allowTextFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
			relatedValueMap: relatedValueMap,
		};

		function config() {
			return {
				id: 4,
				name: 'Salesforce',
				propertyName: 'salesforce',
				getApiConnection: null,
				available: utilities.getDBKPlatform() === 'dbksf',
				editable: true,
				isPrimary: true,
				async: true,
				colorType: 'rgba',
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: 'images/SalesforceBig.png',
				sourceHelp: $sce.trustAsHtml(
					'Here is where you begin describing an object you\'d like to see in the calendar. Enter the name of the Salesforce Object, then give your object the name as it should be displayed in DayBack. Click "Details" for more information about each attrbute.'
				),
				parentSourceHelp: $sce.trustAsHtml(
					'Please select a calendar to the left in order to change that calendar’s settings.'
				),
				fieldMappingHelp: $sce.trustAsHtml(
					"<h4>Please map each of the fields below to a field in your Salesforce object.</h4>If you don't have a particular field, or don't want your calendar users to see the field, just ​un-check​ the show check-box beside the field. If you don't see a checkbox it means that field is required for the calendar to work. \"Label\" defines the field label in the popover. Once you have completed the field mapping, you can validate it below."
				),
				resourceMappingHelp: $sce.trustAsHtml(
					'<h4>Resources are people, trucks, rooms, or processes.</h4><p>Resources are anything in your organization that gets over-scheduled: people, trucks, rooms, or business processes. They drive the resource views in DayBack (Schedule and Grid views) and resources are standard filters in the calendar alongside "status".</p>' +
						'<p>By default, DayBack maps to the Owner/Assigned To of the record (Owner.Name), but you can map to your own fields or related records instead.</p>' +
						'<p>Learn more about Resources here and find specific tips for mapping the resource field here <a href="https://docs.dayback.com/article/43-resources-overview" target="_blank">Resource Scheduling</a>, including notes on using related fields and lookups: <a href="https://docs.dayback.com/article/87-mapping-the-resource-field" target="_blank">Mapping the Resource Field</a>.</p>'
				),
				contactObjectHelp: $sce.trustAsHtml(
					'<h4>Which types of people can be related to this object.</h4>This is where you can specify which types of people (leads or contacts, for example) can be linked to your object. Click "Add new contact object" once for every type of contact you\'d like to be able to link. Leave this section blank if you don\'t want users to be able to edit linked contacts in the calendar.'
				),
				projectObjectHelp: $sce.trustAsHtml(
					'<h4>List the objects this item may be related to.</h4>Can this item be related to a Campaign or a Case? Click "Add new project object" once for each such object that could be a parent of this calendar item. If you don\'t want users to be able to link to other objects in the calendar, then leave this section blank.'
				),
				resourceObjectHelp: $sce.trustAsHtml(
					"<h4>Which field should DayBack present a user when they're selecting a resource.</h4><p>Most often, you'll select a resource by dragging an event to a resource row or column in DayBack. But you can also assign resources when editing an event in DayBack's popover but selecting a resource from a searchable list. The fields below build that list and determine which fields you're searching on.</p>" +
						'<p>The fields below will automatically fill out once you select a Resource ID Field Name above. Usually, you do not need to make any further changes.</p>' +
						'<p>But you may change the options below if you want to search on or display a field other than the "Name" field from your related object. You\'ll find examples of why you might want to do this here: <a href="https://docs.dayback.com/article/87-mapping-the-resource-field#selecting" target="_blank">Mapping the Resource Field</a>.</p>'
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a> or <a href="https://dayback.com/listing/go-to-records-native-salesforce-page/" target="_blank">jumping to a record’s native Salesforce page</a>.</p>'
				),
				useISOTimestamp: true,
				customActionsEditable: true,

				settings: {
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 4,
					},
					objectName: {
						setting: 'objectName',
						visible: true,
						displayValue: 'Object Name',
						defaultValue: '',
						helptext:
							'The name of the Salesforce Object you would like to map to Dayback. Please refer to the <a href="https://developer.salesforce.com/docs/atlas.en-us.object_reference.meta/object_reference/sforce_api_objects_list.htm" target="_blank">Object Reference for Salesforce and Force.com</a> for the Object names and their fields. To be mapped to Dayback, Objects must have at least one Date field to be mapped to Dayback\'s Start field.',
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						defaultValue: 'New Salesforce Calendar',
						format: 'name',
						helptext:
							"The name you would like to use to display the source within DayBack's side bar where sources are toggled on and off. This name is for display purposes only and need not match the actual object name in Salesforce.",
					},
					recordType: {
						setting: 'recordType',
						visible: true,
						displayValue: 'Record Type',
						defaultValue: '',
						format: 'select',
						helptext:
							'The Record Type to use for this Object. Setting a record type will mean only records with that Record Type will display for this source. New items created in this source will be assigned the specified Record Type. If there are no options available for selection, then there are no Record Types set up for this Object.',
					},
					preFilter: {
						setting: 'preFilter',
						visible: false,
						displayValue: 'Filtering',
						defaultValue: '',
						format: 'textarea',
						helptext:
							'Filtering you would like applied to this source. This filtering is always on, and can\'t be disabled by the user. Use the same grammar as the <a href="https://docs.dayback.com/article/114-filter-options" target="_blank">Text Filter</a>.<br><br>For Example:<br><br><div class="exampleCode">Owner.Name: Jack Rogers and Status: Not Started</div>',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(68, 68, 242, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
       						 <div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
         				...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Designating a source as Read-Only will prevent any editing or deleting of the source via the pop-up or drag and drop. This is a UI layer only, to prevent requests from going to Salesforce and failing due to permissions or other organizational restrictions.',
					},
					useAssignedResources: {
						setting: 'useAssignedResources',
						visible: false,
						displayValue: 'Use Assigned Resources',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'When set to yes assigned resources are used in Service Appointments',
					},
					allowUnscheduled: {
						setting: 'allowUnscheduled',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Allow unscheduled events',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'When enabled and a boolean unscheduled field is mapped you can mark events as unscheduled and they will not show in the main calendar view but in the unscheduled section instead. <a href="https://docs.dayback.com/article/294-unscheduled-items" target="_blank">Learn more</a>.',
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: false,
						format: 'defaultResource',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default Popover Drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'location', label: 'Location'},
							{id: 'resource', label: 'Assigned To'},
							{id: 'status', label: 'Show As'},
							{id: 'contact', label: 'Name (Who)'},
							{id: 'project', label: 'Related To (What)'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a drawer that should be shown automatically when clicking on an event.</br> <a href="https://docs.dayback.com/article/167-drawers" target="_blank">Learn more about drawers here.</a>',
					},
					allowAllDay: {
						setting: 'allowAllDay',
						visible: true,
						displayValue: 'Can you assign times to these items?',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Sources that do not allow times will have the All Day option removed from the pop-over and attempts to drag an event from the All Day section to a time in the calendar will be reverted. Sources that allow times will have the All Day checkbox available in the pop-over. This checkbox can be mapped to a boolean field in your Object using the All Day Event in Field Mapping.',
					},
					isUnavailable: {
						setting: 'isUnavailable',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Unavailable',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to true will render any events on this source as unavailable time on the calendar rather than regular events. <a href="https://docs.dayback.com/article/258-availability" target="_blank">Learn more about availability.</a>',
					},
					isMeasureOnly: {
						setting: 'isMeasureOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Analytics Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from displaying but will allow the data from the events to be used in analytics charts.',
					},
					isMapOnly: {
						setting: 'isMapOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Map Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from the calendar but will allow the data to be used in maps.',
					},
					queryOnGeocode: {
						setting: 'queryOnGeocode',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Limit to map boundary',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will change the way events are queried. Rather than query based on dates the events will be queried based on geocode inside the map boundary.',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: false,
						displayValue: 'Backend date format',
						defaultValue: 'YYYY-MM-DD',
					},
					requireFilters: {
						setting: 'requireFilters',
						visible: true,
						displayValue:
							'Don’t show items unless the calendar is filtered',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Calendars with this setting set to Yes will not load items unless filters are applied. <a id="requireFiltersHelp" href="https://docs.dayback.com/article/137-speed" target="_blank">Learn more</a>.',
					},
					includeFilters: {
						setting: 'includeFilters',
						visible: true,
						displayValue:
							'Include resource and status filters in query?',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Calendars with this setting set to Yes will apply the status and resource filters to the SOQL query to SalesForce. This can potentially increase performance for large data sets. However, it may slow down smaller data sets, so some experimentation should be done to see which setting gives your organization the best performance. <a href="https://docs.dayback.com/article/137-speed" target="_blank">Learn more</a>.',
					},
					relatedFirstWord: {
						setting: 'relatedFirstWord',
						visible: true,
						displayValue:
							'Limit searching for related records to search just the first word of the name field.',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Calendars with this setting set to Yes will only search the first name of a related-to record. This is for organizations that have a very large number of Accounts, Opportunities, etc. When this setting is enabled, a search using the criteria "cap" will return a result for "Capital Investments" but not "Investments Capital" <a href="https://docs.dayback.com/article/137-speed" target="_blank">Learn more</a>.',
					},
					allowRelated: {
						setting: 'allowRelated',
						visible: false,
						defaultValue: true,
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
				},
			};
		}

		function fieldMap() {
			return {
				eventID: {
					setting: 'eventID',
					visible: false,
					displayValue: 'ID',
					defaultValue: 'Id',
					required: true,
					unused: false,
					helptext:
						'The primary key or unique ID in your table. For all the fields that follow, use <i>just</i> your field name, not the table and field name. So if your field was called "id" you\'d enter id above, not MyTable::id',
				},
				created: {
					setting: 'created',
					visible: false,
					displayValue: 'Creation Date',
					defaultValue: 'CreatedDate',
					required: false,
					unused: false,
					helptext: 'The date the record was created',
				},
				title: {
					setting: 'title',
					visible: true,
					displayValue: 'Display',
					defaultValue: '',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						'<b>Required</b> The text that appears on the event. You can combine several fields here, seperated by a comma. These fields will be seperated by carriage returns in the Schedule views. You can specify which field to substitute for the carriage return in the other views in "Event Styles."',
				},
				start: {
					setting: 'start',
					visible: true,
					displayValue: 'Start',
					defaultValue: '',
					labelValue: 'Start',
					required: (calendarSource) => {
						return (
							!calendarSource.isMapOnly ||
							!calendarSource.queryOnGeocode
						);
					},
					unused: false,
					helptext:
						'<b>Required</b> The starting date or date/time of the item. Objects with a single date (like Tasks) should map that field here.<br><br> This pick list will show only date fields if "Can you assign times..." above is set to "no": if set to "yes" it will show only date/time fields.',
				},
				end: {
					setting: 'end',
					visible: true,
					displayValue: 'End',
					defaultValue: '',
					labelValue: 'End',
					required: false,
					unused: false,
					helptext:
						'The ending date or date/time of the item. Objects with a single date (like Tasks) can mark this field as Unused.<br><br> This pick list will show only date fields if "Can you assign times..." above is set to "no": if set to "yes" it will show only date/time fields.',
				},
				allDay: {
					setting: 'allDay',
					visible: true,
					displayValue: 'All Day Event',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'If times are allowed for these items, you can map the All Day checkbox to a boolean field in your Object. The only standard Salesforce Object that uses this field is Event, which maps it to "IsAllDayEvent".',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: true,
					displayValue: 'Title',
					defaultValue: '',
					labelValue: 'Enter Title',
					required: true,
					unused: false,
					helptext:
						'<b>Required</b> The editable version of the title in the popover. In contrast to the "Display" field above, this is the field your users will type into for the name of their event.',
				},
				description: {
					setting: 'description',
					visible: true,
					displayValue: 'Description',
					defaultValue: '',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext: 'Description text area in the pop-over.',
				},
				location: {
					setting: 'location',
					visible: true,
					displayValue: 'Location',
					defaultValue: '',
					labelValue: 'Location',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "location" --this is also the field DayBack will use to generate a map.',
				},
				geocode: {
					setting: 'geocode',
					visible: true,
					displayValue: 'GeoCode',
					defaultValue: '',
					labelValue: 'GeoCode',
					required: (calendarSource) => {
						return (
							calendarSource.isMapOnly &&
							calendarSource.queryOnGeocode
						);
					},
					unused: false,
					helptext:
						'Latitude and longitude values separated by a comma',
				},
				resourceID: {
					setting: 'resourceID',
					visible: true,
					displayValue: 'Resource ID Field Name',
					defaultValue: '',
					required: false,
					related: 'resource',
					unused: false,
					helptext:
						'This is the name of the resource ID field in your calendar\'s object. If your resource is a contractor and you have a contractors table, it might be something like ContractorId__c. Note that the fields listed here change if the "…Related Field" switch above is On or Off. If you want to select an ID field here, turn that switch to "On".',
				},
				resource: {
					setting: 'resource',
					visible: true,
					displayValue: 'Resource Name Field',
					defaultValue: '',
					labelValue: 'Resource Name Field',
					required: false,
					related: 'resourceID',
					unused: false,
					helptext:
						'This is the name of your resource and the label you\'d like to see beside that field in DayBack. The options available for "Resource Name Field" change if the "…Related Field" switch above is On or Off. If that switch is On, the options you\'ll see here are the text fields from the relationship governed by the Resource ID Field Name you entered above. For more help, read up on resource fields here: <a href="https://docs.dayback.com/article/87-mapping-the-resource-field" target="_blank">Mapping the Resource Field</a>.',
				},
				status: {
					setting: 'status',
					visible: true,
					displayValue: 'Status',
					defaultValue: '',
					labelValue: 'Status',
					required: false,
					unused: false,
					helptext:
						'The status of the Event. Statuses can be used to further color code and filter Events in this source. See more about statuses <a href="https://docs.dayback.com/article/21-event-colors" target="_blank">here</a>.',
				},
				tags: {
					setting: 'tags',
					visible: true,
					displayValue: 'Tags',
					defaultValue: '',
					labelValue: 'Tags',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "tags" --this is also the field DayBack will use for certain meta-data about your events.',
				},
				unscheduled: {
					setting: 'unscheduled',
					visible: true,
					displayValue: 'Unscheduled',
					defaultValue: '',
					labelValue: 'Unscheduled',
					required: false,
					unused: false,
					helptext:
						'The field that holds a boolean value to indicate if the event should be treated as unscheduled. Map to a checkbox field. <a href="https://docs.dayback.com/article/294-unscheduled-items" target="_blank">Learn more</a>.',
				},
				contactID: {
					setting: 'contactID',
					visible: true,
					displayValue: 'Name ID',
					defaultValue: '',
					required: false,
					related: 'contactName',
					unused: false,
					helptext:
						'The ID for the related Name. For standard Activities this is the WhoId for the Activity, but can be any Name Foreign Key in the Event Object. You must map this field to populate the choices for the Name value below',
				},
				contactName: {
					setting: 'contactName',
					visible: true,
					displayValue: 'Name (Who)',
					defaultValue: '',
					labelValue: 'Name',
					required: null,
					related: 'contactID',
					unused: false,
					helptext:
						'The Name related to this event. For standard Activities this is the Who.Name of the Activity, but can be any related Name. Since these are related values, the ID field must be specified above to properly read and edit this field.',
				},
				projectID: {
					setting: 'projectID',
					visible: true,
					displayValue: 'Related To ID',
					defaultValue: '',
					required: false,
					related: 'projectName',
					unused: false,
					helptext:
						'The ID for the Related To. For standard Activities this is the WhatId for the Activity, but can be any Object Foreign Key in the Event Object. You must map this field to populate the choices for the Related To value below',
				},
				projectName: {
					setting: 'projectName',
					visible: true,
					displayValue: 'Related To (What)',
					defaultValue: '',
					labelValue: 'Related To',
					required: null,
					related: 'projectID',
					unused: false,
					helptext:
						'The Object related to this event. For standard Activities this is the What.Name of the Activity, but it can be any related Object. Since these are related values, the Id field must be specified above to properly read and edit this field.',
				},
				recurringEventID: {
					setting: 'recurringEventID',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceActivityId',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				IsRecurrence: {
					setting: 'IsRecurrence',
					visible: false,
					displayValue: '',
					defaultValue: 'IsRecurrence',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceStartDateTime: {
					setting: 'RecurrenceStartDateTime',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceStartDateTime',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true},
				},
				RecurrenceStartDateOnly: {
					setting: 'RecurrenceStartDateOnly',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceStartDateOnly',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Task: true},
				},
				RecurrenceEndDateOnly: {
					setting: 'RecurrenceEndDateOnly',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceEndDateOnly',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceTimeZoneSidKey: {
					setting: 'RecurrenceTimeZoneSidKey',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceTimeZoneSidKey',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceType: {
					setting: 'RecurrenceType',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceType',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceInterval: {
					setting: 'RecurrenceInterval',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceInterval',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceDayOfWeekMask: {
					setting: 'RecurrenceDayOfWeekMask',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceDayOfWeekMask',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceDayOfMonth: {
					setting: 'RecurrenceDayOfMonth',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceDayOfMonth',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceInstance: {
					setting: 'RecurrenceInstance',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceInstance',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
				RecurrenceMonthOfYear: {
					setting: 'RecurrenceMonthOfYear',
					visible: false,
					displayValue: '',
					defaultValue: 'RecurrenceMonthOfYear',
					labelValue: '',
					required: null,
					unused: false,
					allowedSchedules: {Event: true, Task: true},
				},
			};
		}

		function unusedMap() {
			return {
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}

		function hiddenFieldMap() {
			return {};
		}

		function readOnlyFieldMap() {
			return {};
		}

		function relatedValueMap() {
			return {};
		}

		function allowTextFieldMap() {
			return {};
		}
	}
})();
